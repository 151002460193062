<template>
  <v-card>
    <v-card-text style="margin-top:20px">
      <v-slider v-model="evaluation.presentation" :readonly="!isEvaluator" :step="isSummary ? .1 : 1" :tick-size="isEvaluator ? 4 : 0" thumb-label="always" min="1" max="10" ticks="always" label="Presentation">
        <template v-slot:label>
          <div style="width:100px">Presentation</div>
        </template>
      </v-slider>
      <v-slider v-model="evaluation.preparation" :readonly="!isEvaluator" :step="isSummary ? .1 : 1" :tick-size="isEvaluator ? 4 : 0" thumb-label="always" min="1" max="10" ticks="always" label="Preparation">
        <template v-slot:label>
          <div style="width:100px">Preparation</div>
        </template>
      </v-slider>
      <v-slider v-model="evaluation.persona" :readonly="!isEvaluator" :step="isSummary ? .1 : 1" :tick-size="isEvaluator ? 4 : 0" thumb-label="always" min="1" max="10" ticks="always" label="Persona">
        <template v-slot:label>
          <div style="width:100px">Persona</div>
        </template>
      </v-slider>
      <v-slider v-model="evaluation.progress" :readonly="!isEvaluator" :step="isSummary ? .1 : 1" :tick-size="isEvaluator ? 4 : 0" thumb-label="always" min="1" max="10" ticks="always" label="Progress">
        <template v-slot:label>
          <div style="width:100px">Progress</div>
        </template>
      </v-slider>
      <v-slider v-model="evaluation.potential" :readonly="!isEvaluator" :step="isSummary ? .1 : 1" :tick-size="isEvaluator ? 4 : 0" thumb-label="always" min="1" max="10" ticks="always" label="Potential">
        <template v-slot:label>
          <div style="width:100px">Potential</div>
        </template>
      </v-slider>
      <template v-if="Array.isArray(evaluation.comment)">
        <h2 style="margin-bottom:10px">Comments</h2>
        <v-textarea v-for="({ comment, name }, index) in evaluation.comment" :key="'comment-' + index" :label="name" :value="comment" rows="2" readonly auto-grow></v-textarea>
      </template>
      <v-textarea v-else v-model="evaluation.comment" :readonly="!isEvaluator" label="General Comments" outlined></v-textarea>
    </v-card-text>
    <v-card-actions v-if="isEvaluator">
      <v-btn text color="success" @click="$emit('save', evaluation)">Save Evaluation</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, reactive, watch } from '@vue/composition-api'
export default {
  props: {
    defaultEvaluation: {
      type: Object,
      default: () => {
        return {
          isNew: true,
          presentation: 1,
          preparation: 1,
          persona: 1,
          progress: 1,
          potential: 1,
          comment: ''
        }
      }
    }
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isEvaluator = computed(() => {
      if (root.$store.getters.hasRole(['Technology Services', 'Employee']) && props.defaultEvaluation.isNew) return true
      if (user.value.username === props.defaultEvaluation.evaluator.username) return true
      return false
    })
    const evaluation = reactive({ ...props.defaultEvaluation })

    const isSummary = computed(() => Array.isArray(props.defaultEvaluation.comment))

    watch(() => props.defaultEvaluation, () => {
      for (let l in props.defaultEvaluation) {
        evaluation[l] = props.defaultEvaluation[l]
      }
      if ('isNew' in evaluation && !('isNew' in props.defaultEvaluation)) delete evaluation.isNew
    })

    return {
      isEvaluator,
      evaluation,
      isSummary
    }
  }
}
</script>
